.App {
  text-align: center;
  /* height: 100% !important;
  background-color: "#e0eae659" !important; */
  background-color: #ffffff00;
  /* background-color: #F5F5F5; */
  min-height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.vendorInfo {
  /* Add any additional styling for the vendor info */
}


.quoteCardContainer {
  margin-bottom: 10px;
}

.selected {
  border: 2px solid tomato; 
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5)!important;
  transition: border-color 0.3s, box-shadow 0.3s!important;
}

.notSelected {
  border: 2px solid #e0eae659;
}

.requestServicesButton {
  padding-bottom: 10px;
}

.createJobError {
  color: red;
  margin-bottom: 20px;
  font-size: 30px;

}

/* //////////////////Quotes Small////////////////// */
/* .quotesSmall {
  align-items: center;
  justify-content: center;
  display: flex;
}

.quotesSmall .vendorLogo {
  height: 8rem;
  margin-top: 10px;
}

.quotesSmall .vendorInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.quotesSmall .vendorInfoLogo {
  /* width: max-content; */

}

/* //////////////////Quotes Medium////////////////// */
.quotesMedium {
  align-items: center;
  justify-content: center;
  display: flex;
}

.quotesMedium .vendorLogo {
  height: 8rem;
  margin-top: 10
}

.quotesMedium .vendorInfoContainer {
  display: flex;
  align-items: center;
  /* Center items vertically */
  justify-content: center;
  /* Center items horizontally */
}

.quotesMedium .vendorInfoLogo {
  margin-right: 10px;
  /* Adjust the margin as needed for spacing between logo and info */
  margin-top: 10px;
  margin-bottom: 10px
}

/* //////////////////Quotes Large////////////////// */
.quotesLarge {
  align-items: center;
  justify-content: center;
  display: flex;
}

.quotesLarge .vendorLogo {
  height: 8rem;
  margin-top: 10
}

.quotesLarge .vendorInfoContainer {
  display: flex;
  align-items: center;
  /* Center items vertically */
  justify-content: center;
  /* Center items horizontally */
}

.quotesLarge .vendorInfoLogo {
  margin-right: 10px;
  /* Adjust the margin as needed for spacing between logo and info */
  margin-top: 10px;
  margin-bottom: 10px
}

/* /////////////Payment Pages///////////// */

/* ////////////Payment Small///////////// */



/* ////////////Payment Small///////////// */
#vendorDashboard {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
}



.react-datepicker__day--selected {
  background-color: #4f8f2f !important; /* Use !important to override default styles */
  color: white !important;
}

/* Change the background color of the selected time */
.react-datepicker__time-list-item--selected {
  background-color: #4f8f2f !important;
  color: white !important;
}




/* Change the background color of the schedule button */
.react-datepicker__close-icon::after {
  background-color: #4f8f2f !important;
}

/* Change the color of the schedule button icon */
.react-datepicker__close-icon::after {
  color: white !important;
}

/* Change the background color of the close button */
.react-datepicker__close-icon::before {
  background-color: #4f8f2f !important;
}

/* Change the color of the close button icon */
.react-datepicker__close-icon::before {
  color: white !important;
}






/* No need for .tooltip styles */

/* Adjust as needed */
/* For example: */
/* TableCell component hover */
.TableCell:hover {
  position: relative;
}

/* Tooltip on hover */
.TableCell:hover::after {
  content: attr(title);
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  text-align: center;
  padding: 4px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: calc(100% + 8px);
  transform: translateY(-50%);
}

/* Adjust tooltip arrow as needed */
.TableCell:hover::after {
  /* tooltip arrow */
  border-width: 5px;
  border-style: solid;
  border-color: transparent rgba(0, 0, 0, 0.75) transparent transparent;
}
